<template>
  <section class="section">
    <div class="container">
      <b-loading
        v-model="loading"
        :is-full-page="false"
        class="is-primary"
      >
      </b-loading>
      <div v-if="formId">
        <b-button
          tag="router-link"
          :to="{ name: 'professionalaccess-incorporation' }"
          icon-left="chevron-left"
          type="is-text"
        >
          {{ $t('details.backToRequests') }}
        </b-button>
        <h1 class="is-size-3 mb-0">
          {{ $t('details.requestNumber', [formId.toUpperCase()]) }}
        </h1>
        <h2>{{ $t(`formType.${currentForm}`) }}</h2>
        <component
          v-for="(step, stepIndex) in reviewSteps"
          :key="stepIndex"
          :is="`page-${step.component}`"
          class="page-confirmation__readonly"
        >
        </component>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/api';
import { mapActions, mapState } from 'vuex';
import { getIncorporationModel } from '@/utils/helper';

export default {
  name: 'IncorporationDetails',
  components: {
    PageCompanyName: () => import('@/components/page/PageCompanyName'),
    PageDirector: () => import('@/components/page/PageDirector'),
    PageFounder: () => import('@/components/page/PageFounder'),
    PageHeadOffice: () => import('@/components/page/PageHeadOffice'),
    PageOfficer: () => import('@/components/page/PageOfficer'),
    PageOptions: () => import('@/components/page/PageOptions'),
    PageOther: () => import('@/components/page/PageOther'),
    PageProfessionalOrder: () => import('@/components/page/PageProfessionalOrder'),
    PageShare: () => import('@/components/page/PageShare'),
    PageShareholder: () => import('@/components/page/PageShareholder')
  },
  data () {
    return {
      loading: true,
      formId: null
    };
  },
  computed: {
    ...mapState('form', [
      'currentForm',
      'steps',
      'model'
    ]),
    reviewSteps () {
      return this.steps.length ? this.steps.slice(0, this.steps.findIndex(step => step.component === 'confirmation')) : [];
    }
  },
  mounted () {
    this.formId = this.$route.params.id;
    api.getIncorporationFormById(this.formId)
      .then((formResp) => {
        this.setViewModeReadOnly();
        this.setForm(formResp.data.formType);
        this.setModel(getIncorporationModel(formResp.data.form));
        // this.setFiles
        this.updateSteps();

        this.loading = false;
      })
      .catch(() => {
        this.formId = null;
        this.$router.push('/');
      });
  },
  methods: {
    ...mapActions('form', [
      'setFiles',
      'setForm',
      'setModel',
      'updateSteps'
    ]),
    ...mapActions('viewMode', [
      'setViewModeReadOnly'
    ])
  }
};
</script>
